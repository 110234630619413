/* eslint-disable react-hooks/exhaustive-deps */
// import { Switch } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import getPharmacyList from "../Pharmacy/utils/getPharmacyList";
import GridTables, { TagList } from "./TableFeatureFlag";

type HeaderTableFormat = {
    name: string, span: number, keys: string; custom?: (e: any) => any;
};

const TabFeatureCompany = () => {
    const [option, setOption] = useState([]);
    const [loadingOption, setLoadingOption] = useState(false);
    const getPharmacy = async () => {
        setLoadingOption(true);
        try {
            const listPharmacy = await getPharmacyList();
            setOption(listPharmacy);
            setLoadingOption(false);
        } catch (err) {
            setLoadingOption(false);
        }
    };

    useEffect(() => {
        getPharmacy();
    }, []);

    const titles: HeaderTableFormat[] = [
        {
            name: 'Group', span: 5, keys: 'name',
        },
        {
            name: 'Companies Name', span: 12, keys: 'Company',
            custom: (e: any) => {
                const groupsData = e?.company ? e?.company : [];
                const list = groupsData?.reduce((collect: { name: string, id: number; }[], item: any) => [...collect, { id: item?.id, name: `${item?.name}, ${item?.city_name}` }], []);
                const defaultSelected = groupsData?.reduce((collect: number[], newData: any) => [...collect, newData?.id], []);
                const setNewFormData = () => {
                    e.setForm({ ...e.form, company_ids: defaultSelected });
                };
                const setFormValue = (data: any) => {
                    e?.setForm({ ...e.form, company_ids: data });
                };
                return <TagList
                    setNewFormData={setNewFormData}
                    option={option}
                    setFormValue={setFormValue}
                    formValue={e.form.company_ids}
                    e={e}
                    defaultSelected={defaultSelected}
                    list={list}
                    loading={loadingOption}
                />;
            }
        },
        {
            name: 'Created at', span: 3, keys: 'created',
            custom: (e: any) => {
                return (
                    <div>
                        {moment(e.created_at).format('DD-MM-YYYY')}
                    </div>
                );
            }
        },
    ];


    return (
        <div style={{ background: 'white', padding: '4px', borderRadius: '5px' }}>
            <GridTables
                header={titles}
                searchKey={['name']}
                titleButton={{ add: "Add new Group" }}
                endPoint="/system/feature/company"
                useCRUD={{
                    add: true,
                    edit: true,
                    delete: true,
                    api: '',
                    span: 4,
                    initialForm: {
                        name: '',
                        company_ids: []
                    }
                }}
            />
        </div>
    );
};

export default TabFeatureCompany;






